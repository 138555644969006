export default {
  data() {
    return {

    }
  },
  methods: {
    interfaceBack(code, text) {
      var codeVal = code.toString()
      switch (codeVal) {
        case '0007':
          this.$message({
            tipTxt: '您的登录信息已过期，请重新登录',
            isLink: false
          });
          break;
        case '2020':
          this.$message({
            tipTxt: '您的账号已注册',
            isLink: false
          });
          break;
        case '0002':
          this.$message({
            tipTxt: '请输入账号/密码/验证码',
            isLink: false
          });
          break;
        // case '0003'://下方
        // case '0049'://下方
        // case '0048'://下方
        // case '0009'://下方
        case '0001':
        case '0004':
        case 'd0006':
        case '0008':
        case '0010':
        case '0013':
        case '0014':
        case '0015':
        case '0016':
        case '0018':
        case '0019':
        case '0020':
        case '1000':
        case '1003':
        case '1004':
        case '1006':
        case '1007':
        case '1008':
        case '2001':
        case '2002':
        case '2003':
        case '2004':
        case '2005':
        case '2006':
        case '2007':
        case '2008':
        case '2009':
        case '2010':
        case '2011':
        case '2012':
        case '2013':
        case '2014':
        case '8999':
        case '9996':
        case '9997':
        case '9998':
        case '2022':
        case '-1':
        case '999':
          this.$message({
            tipTxt: text,
            isLink: false
          });
          break;
        default: {
          this.$message({
            tipTxt: '服务器开小差了，请稍后再试',
            isLink: false
          });
          break;
        }
      }
    }
  }
}